import React from 'react';
import MESSAGES from '@messages';
import SchedulePage from '../templates/schedule-page';

const CrashTest = () => (
  <>
    <button onClick={() => {
     throw new Error('throw test error');
    }}>throw test error</button>

<button onClick={() => {
      console.log({ a })
    }}>crash test</button>
  </>
);

export default CrashTest;
